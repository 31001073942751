const en = {
  AUDIO_GUIDE: {
    BANNER: 'Free Audio Guide',
    PRODUCT_SUFFIX: 'with Audio Guide',
  },
  AUTHORISED_AND_TRUSTED_PARTNER: 'Authorised & Trusted Partner',
  AUTHORISED_RESELLER: {
    MAIN_TEXT: 'Authorized reseller',
    SUB_TEXT: 'Authentic experiences from trusted and verified partners.',
  },
  REVIEWS: 'reviews',
  AVAILABLE: 'Available',
  BANNER_CTA: 'Buy tickets',
  BANNER_FOOTER_SUBTEXT: {
    NON_PARTNERED_NON_SENSITIVE:
      'Headout is a brand trusted by millions of guests worldwide offering curated experiences to enjoy this attraction.',
    NON_PARTNERED_SENSITIVE:
      "Headout is a brand trusted by millions of guests worldwide offering curated experiences to enjoy this attraction. This is not the venue's website.",
    PARTNERED_NON_SENSITIVE:
      'Headout is an authorized and trusted partner of the venue, offering curated experiences to enjoy this attraction.',
    PARTNERED_SENSITIVE:
      "Headout is an authorized and trusted partner of the venue, offering curated experiences to enjoy this attraction. This is not the venue's website.",
  },
  BANNER_SUBTEXT_DEFAULT_DISCLAIMER:
    'Headout is an authorised and trusted partner of the venue offering a curated selection of the best experiences to enjoy this attraction.',
  BOOK_NOW_CTA: 'Book now',
  BUY_TICKETS_CTA: 'Buy tickets',
  CANCELLATION_POLICY: {
    CANCELLABLE:
      'You can cancel these tickets up to {0} hours before the experience begins and get a full refund.',
    CANCELLABLE_ANYTIME:
      'Free cancellation anytime before the start of your experience',
    CANCELLABLE_DAYS:
      'You can cancel these tickets up to {0} days before the experience begins and get a full refund.',
    EXTENDED_BUT_UNKNOWN_VALIDITY:
      'These tickets can’t be cancelled. However, they are valid for an extended period of time.',
    HEADING: 'Amendment Policy',
    NON_CANCELLABLE_BUT_RESCHEDULABLE:
      "These tickets can't be cancelled. However, they can be rescheduled up to {0} hours before the experience begins.",
    NON_CANCELLABLE_NON_RESCHEDULABLE:
      "These tickets can't be cancelled or rescheduled.",
    VALID_UNTIL_DATE:
      "These tickets can't be cancelled. However, you can use them any time until {0}.",
    VALID_WITHIN_NEXT_DAYS:
      'These tickets can’t be cancelled. However, you can use them any time within the next {0} days.',
    VALID_WITHIN_NEXT_MONTHS:
      'These tickets can’t be cancelled. However, you can use them any time within the next {0} months.',
  },
  CANCELLATION_POLICY_HEADING: 'Cancellation policy',
  CASHBACK: '{0}% cashback',
  CASHBACK_INFO:
    'You will receive <strong>{0}%</strong> cashback when you book this experience. Your cashback will automatically be credited to your Headout account and can be used towards any future booking with Headout.',
  CATEGORY_SLIDER_HEADING: 'Customers Who Booked This Show Also Viewed',
  CHECK_AVAIL: 'Check availability',
  CITY_PAGE: {
    BANNER_TITLE: 'Experience the best of',
    TOP_ATTRACTIONS: 'Top attractions',
    GO_BEYOND: 'Go beyond {0}',
    ALL_DAY_TRIPS: 'See all day trips',
    CITIES_NEARBY: 'Nearby cities',
    POPULAR_CATEGORIES: 'Popular categories',
    GO_TO_GUIDE: 'Go-to {0} guide',
    VIEW_TRAVEL_GUIDE: 'View travel guide',
    EXPLORE: 'Explore {0}',
    GUIDE_SUBHEADING:
      'Exploring {0} has never been easier! Our {0} travel guide has all you need to know about the city, from what to eat and what to see to how to get there, where to stay and plenty more!',
    GUIDE_ITEM_HEADINGS: {
      TRAVEL_GUIDE: 'General information',
      WHERE_TO_EAT: 'What to eat',
      WHERE_TO_STAY: 'Where to stay',
      THINGS_TO_DO: 'What to do',
      WEATHER: 'Best time to visit',
      NEIGHBOURHOODS: 'Local community',
      TRANSPORTATION: 'Getting around',
      TRIP_PLANNER: 'Travel plan',
      FAMILY_TRAVEL: 'Traveling with kids',
      TRAVEL_TIPS: 'Travel tips & hacks',
      SHOPPING: 'Shopping',
      ART_AND_CULTURE: 'Arts & culture',
    },
    MAILER: {
      HEADING: '{0} travel inspiration straight to your inbox',
      SUBHEADING:
        'Explore new cities with curated experiences, instant cashback, exclusive deals and essential travel tips!',
    },
  },
  COOKIE_CONSENT: {
    BASIC_DESCRIPTION:
      'We use cookies and similar technologies for many purposes, including to improve your experience on our site and for ads.',
    MANAGE_PREFERENCES: 'Manage',
    PREFERNCES_SAVED: 'Your preferences have been saved',
    COOKIES: 'Cookies:',
    ACCEPT: 'Okay',
    ALLOW_ALL: 'Allow all',
    DENY_ALL: 'Deny all',
    HEADING: 'We value your privacy, always',
    DESCRIPTION: `*   Cookies are small files placed on Your computer, mobile device, or any other device by Website/Platform, containing the details of Your browsing history among its many uses.
*   We use different types of cookies to track Your usage of Our Platform and store certain information that further helps us to provide You with more personalized services. Certain cookies may get placed on Your Device because of Your usage of any services available on Our Platform.
*   Broadly, We use two different sets of technologies under this head:
    *   **Cookies or Browser Cookies.** it is a small file that automatically gets placed on Your Device when You access Our Platform. You can instruct Your browser to refuse all Cookies. In case, if You do not accept Our Cookies, You may not be able to use some parts of Our Platform.
    *   **Web Beacons.** Also referred to as clear gifs, pixel tags, and single-pixel gifs that are attached in various sections of Our Platform and Our emails that permit us to monitor and understand the activity of Users.
*   These Cookies stored on Your Device can be "Persistent" or "Session" Cookies. Persistent Cookies are the ones that remain on Your Device even when You go offline, whereas, Session Cookies are automatically deleted as soon as You close Your web browser.
*   We use both Session and Persistent Cookies for the purposes set out below:
    *   Necessary / Essential Cookies
        *   _Type:_ Session Cookies
        *   _Purpose:_ These Cookies are essential for You to access different sections of the Platform and if these are not allowed You may not be able to access the services available on the Platform. These cookies further help us to authenticate the Users and prevent any sort of fraudulent activity on the Platform.
    *   Cookies Policy / Notice Acceptance Cookies
        *   _Type:_ Persistent Cookies
        *   _Purpose:_ These Cookies identify if Users have accepted the use of Cookies.
    *   Functionality Cookies
        *   _Type:_ Persistent Cookies
        *   _Purpose:_ These Cookies allow us to remember choices You make when You use the Platform, such as remembering Your login details or language preference. The larger purpose of these Cookies is to provide You with a more personalized experience every time You use the Platform.

All capitalized terms used above are defined in the Privacy Policy. For a detailed understanding please refer [Privacy Policy](/privacy-policy/)
`,
  },
  CITY_GUIDE_DESCRIPTIONS: {
    TRAVEL_GUIDE:
      'Learn about visa requirements, currency exchange, time zone differences, and more to ensure a smooth arrival and enjoyable stay.',
    WHERE_TO_EAT:
      'Indulge in the vibrant culinary scene, savoring diverse flavors and enjoying local delicacies in every corner of the {0}.',
    WHERE_TO_STAY:
      'Discover the best accommodations {0}, offering comfort, convenience, and a memorable experience for your stay.',
    THINGS_TO_DO:
      'Experience a plethora of exciting activities and attractions that cater to every interest and ensure an unforgettable time.',
    WEATHER:
      'Plan your trip wisely and make the most of your visit, as {0} offers enchanting experiences throughout the year.',
    NEIGHBOURHOODS:
      'Get to know the diverse local communities and vibrant neighborhoods that give {0} its unique character.',
    TRANSPORTATION:
      'Navigate {0} with ease using its efficient transportation system, providing convenient options for exploring all its attractions.',
    TRIP_PLANNER:
      'Maximize your time in {0} with carefully curated itineraries, ensuring you make the most of every moment.',
    FAMILY_TRAVEL:
      'Embark on a family-friendly adventure with numerous attractions, parks, and entertainment options that cater to kids of all ages.',
    TRAVEL_TIPS:
      'Discover insider tips and travel hacks to enhance your journey, making your visit to {0} truly exceptional.',
    SHOPPING:
      "Explore a shopper's paradise with a wide range of boutiques, markets, and malls, offering everything from local crafts to high-end fashion.",
    ART_AND_CULTURE:
      'Immerse yourself in the rich art and cultural scene, from captivating museums to vibrant festivals celebrating {0} heritage.',
  },
  CLOSE: 'Close',
  COLLECTION_SLICE_HEADING: 'Top things to do in {0}',
  COMBO_VARIANT: {
    SELECT_CTA: 'Select',
    SELECT_PREFERENCE: 'Select your preference',
    SELECT_OPTION: 'Select your option',
    SELECT_TICKET: 'Select your ticket',
  },
  COMPARE_ALL_DETAILS: 'Compare all details',
  COVID19_ALERT: {
    KEY_TEXT: 'Update on COVID-19',
    LINK: 'https://medium.com/headout/coronavirus-outbreak-cancellation-policy-8fe8e1104b83',
    TEXT: 'You are our priority. Please check our help page to find the latest updates on our flexible cancellation policies',
  },
  CURRENCY: 'Currency',
  CUSTOMER_REVIEW_HEADING: 'Customer reviews',
  DESCRIPTORS: {
    AUDIO_GUIDE: 'Audio guide',
    DURATION: 'Duration: {0}',
    EXTENDED_VALIDITY: 'Extended validity',
    FLEXIBLE_DURATION: 'Flexible duration',
    FREE_CANCELLATION: 'Free cancellation',
    GUIDED_TOUR: 'Guided tour',
    HOTEL_PICKUP: 'Hotel pickup',
    INSTANT_CONFIRMATION: 'Instant confirmation',
    MEALS_INCLUDED: 'Meals included',
    MOBILE_TICKET: 'Mobile ticket',
    SKIP_THE_LINE: 'Skip the line',
    TRANSFERS: 'Transfers included',
    MULTILINGUAL_AUDIO_GUIDE: 'Multilingual audioguide',
    LIVE_GUIDE: 'Live guide',
    FLEXIBLE_CANCELLATION: 'Flexible cancellation',
  },
  DURATION: 'Duration',
  EARLIEST_AVAILABILITY: 'Earliest availability',
  EMAIL_SUBSCRIPTION: {
    EMAIL_FIELD_PLACEHOLDER: 'Enter your email here',
    SIGN_UP: 'Sign up',
    THANK_YOU: 'Thank you!',
    SUBSCRIBED_MSG: 'You are subscribed now',
    ERROR_MSG: 'Please enter a valid email',
  },
  ENTERTAINMENT_MB: {
    BROADWAY: {
      MB_NAME: 'Broadway Theater Tickets',
    },
    LTT: {
      MB_NAME: 'London Theater Tickets',
    },
  },
  EXCLUSIONS: 'Exclusions',
  FEATURE_CARD: {
    HEADING_CUSTOMER: '{0} million +',
    HEADING_RATING: '4.4/5',
    HEADING_SAFE: '100% Safe',
    HEADING_SUPPORT: '24x7 Help center',
    SUB_HEADING_CUSTOMER: 'Happy customers across 10,000+ experiences',
    SUB_HEADING_RATING: '5,000+ verified reviews with 90% excellent score',
    SUB_HEADING_SAFE: 'All the outdoor activities are 100% safe and secure',
    SUB_HEADING_SUPPORT:
      'Have a question? Live chat with local experts anywhere, anytime',
  },
  FLEXIBLE: 'Flexible',
  FOOTER: {
    CALL_US: 'Call us',
    CHAT_WITH_US: 'Chat with us',
    COMPANY_DETAILS: 'Company details',
    ABOUT_US: 'About us',
    INFORMATION: 'Information',
    GMAPS_DISCLAIMER:
      'All screenshots of maps found on this page are sourced from Google in accordance with fair use. All rights to such screenshots lie with the specific platform, and their use is solely for informational or guidance purposes.',
    DISCLAIMER:
      'This website is not the official website of the <attraction>. It is run by Headout and works with attractions and tour operators to provide incredible experiences at the tap of a button.',
    EMAIL_US: 'Email us',
    GET_HELP: 'Get help',
    LEGAL: 'Legal',
    PRIVACY_POLICY: 'Privacy policy',
    QUICK_LINKS: 'Quick Links',
    TERMS_AND_CONDITIONS: 'Terms & conditions',
    COPYRIGHT: '© Headout Inc.',
    MADE_WITH: 'Made with ',
    ALL_OVER_THE: ' all over the ',
    TERMS_OF_USAGE: 'Terms of usage',
    GET_HELP_24_7: 'Get help 24/7',
    HELP_CENTER: 'Help center',
    COMPANY: 'Company',
    WE_ACCEPT: 'We accept',
    DOWNLOAD_HEADOUT: 'Download the Headout app',
    SCAN_CODES: 'Scan code',
    STAR_VERIFIED: 'click to verify S.T.A.R membership',
    OFFICIAL_TICKET_RETAILER: 'Official ticket retailer',
  },
  FREE_CANCELLATION: 'Free cancellation',
  FREE_CANCELLATION_SUBTEXT:
    'We understand plans change, cancel for free up to 72 hours before the event.',
  FROM: 'From',
  GET_CASHBACK: 'Get {0}% cashback',
  GROUP_TICKETS: 'Group tickets',
  HAPPY_CUSTOMER: {
    MAIN_TEXT: '{0}+ Million happy customers',
    SUB_TEXT:
      'Customer satisfaction is our top priority. Join our happy customer base.',
  },
  HEADOUT: 'Headout',
  HELP_CENTER: {
    MAIN_TEXT: '24x7 help center',
    SUB_TEXT:
      'Questions or concerns? We’re here to help 24/7 — on phone, chat, and email.',
  },
  INCLUSIONS: 'Inclusions',
  LANGUAGE: 'Language',
  LANGUAGES: {
    ENGLISH: 'English',
    SPANISH: 'Spanish',
    FRENCH: 'French',
    ITALIAN: 'Italian',
    GERMAN: 'German',
    PORTUGUESE: 'Portuguese',
    NEDERLANDS: 'Dutch',
    POLISH: 'Polish',
    RUSSIAN: 'Russian',
    DANISH: 'Danish',
    NORWEGIAN: 'Norwegian',
    ROMANIAN: 'Romanian',
    SWEDISH: 'Swedish',
    TURKISH: 'Turkish',
  },
  TOUR_AVAILABLE_LANGUAGES: 'Tour available in {0}',
  LISTICLES: {
    KNOW_MORE: 'Know more',
    SEATING_CHART: 'Seating chart',
    WHY_BOOK_FROM_US: 'Why book from us?',
  },
  MORE_DETAILS: 'More details',
  MORE_CURRENCIES: 'More currencies',
  NEW: 'NEW',
  NEW_ARRIVAL: 'New arrival',
  NEXT_AVAILABLE: 'Next available: ',
  NON_PARTNERED_BANNER_SUBTEXT_DISCLAIMER:
    "Headout is a brand trusted by over {0} million guests that offers a curated selection of the best experiences. This is not the venue's website.",
  NO_RESULT_FOUND: 'Hmm, no results found.',
  OFF: 'Off',
  OPEN_DATED_DESCRIPTOR: 'Book with flexibility - no date selection needed',
  OPENING_DATE: 'Opening date',
  OPENING_ON: 'Opening on',
  OPENS: 'Opens',
  PARTNERED_BANNER_SUBTEXT_DISCLAIMER:
    "Headout is an authorised and trusted partner of the venue offering a curated selection of the best experiences to enjoy this attraction. This is not the venue's website.",
  POPULAR_CURRENCIES: 'Popular currencies',
  PRICES_STARTING: 'Prices starting',
  PROMO_CODES: {
    APPLY_CODE: 'APPLY CODE',
    APPLIED: 'APPLIED',
    TOOL_TIP_INFO: 'This discount is applied at checkout',
    DESCRIPTION: {
      ABSOLUTE: 'Get {0} off',
      CAPPED: 'Get {0}% off, upto {1}',
      PERCENTAGE: 'Get {0}% off',
    },
    REMOVE: 'REMOVE',
  },
  RATINGS: '{0} ratings',
  READ_MORE: 'Read more',
  READ_MORE_TEXT: 'Read more',
  READ_LESS: 'Read less',
  VIEW_DETAILS: 'View details',
  REOPENING: 'Reopening',
  REOPENING_DATE: 'Reopening date',
  REOPENING_ON: 'Reopening on',
  REOPENS: 'Reopens',
  SAFE_EXPERIENCE: {
    AT_DESCRIPTION: {
      CTA_URL:
        'https://www.austria.info/en/service-and-facts/coronavirus-information/entry-regulations',
      TEXT: '2G regulations apply: access to accommodation, cultural institutions, and restaurants/bars, among others is only possible with proof of full vaccination or past infection. A negative Covid-19 test is no longer sufficient.',
    },
    AU_DESCRIPTION: {
      CTA_URL:
        'https://www.smartraveller.gov.au/COVID-19/COVID-19-vaccinations',
      TEXT: 'Visitors aged 16 and above must show proof of COVID-19 vaccination or medical exemption. ',
    },
    BANNER_CTA: 'Know more',
    BERLIN_DESCRIPTION: {
      CTA_URL:
        'https://www.berlin.de/en/news/coronavirus/7081102-6098215-berlin-introduces-extensive-2G-rule.en.html',
      TEXT: '2G regulations apply: only fully-vaccinated and recovered guests with proof of the same will be granted access. Guests under 18 or those who cannot be vaccinated for medical reasons must present a negative COVID test result at the entrance. Those who cannot be vaccinated for medical reasons should also prove their inability to be vaccinated by means of a medical certificate at the entrance.',
    },
    CTA_TEXT: 'Safety details',
    DESCRIPTION_CTA: 'Know more.',
    EU_DESCRIPTION: {
      CTA_URL:
        'https://www.diplomatie.gouv.fr/en/coming-to-france/coronavirus-advice-for-foreign-nationals-in-france/coronavirus-statements/article/applying-for-a-covid-certificate-if-you-were-vaccinated-abroad-procedure-for',
      TEXT: 'From 21 July 2021, all visitors aged 12 and above must present an EU COVID Certificate. ',
    },
    EU_HEADING: 'EU Covid certificate required',
    EU_PREFIX: 'Note: ',
    EXPERIENCE_DESCRIPTION:
      'This experience has been verified to follow best safety standards in compliance with local regulations and WHO',
    FLAG_TEXT: 'Best safety',
    GENERAL_DESCRIPTION:
      "Any experience with this badge has been verified to follow the best safety standards as per the local government's health guidelines.",
    HEADING: 'Best safety standards',
    IT_DESCRIPTION: {
      CTA_URL: 'https://www.dgc.gov.it/web/per-cosa-serve.html#viaggi',
      TEXT: 'From 6 August 2021, all visitors aged 11 and above must present a Green Pass or EU COVID Certificate. ',
    },
    IT_PREFIX: 'Note: ',
    MOBILE_HEADING: 'Safe experiences',
    MODAL: {
      BADGE_DESCRIPTION:
        'This badge on an experience confirms that safety standards have been verified by Us',
      DETAILS: {
        SAFETY_CLEANED_DEFAULT: {
          DESCRIPTION:
            'All equipments, vehicles and high-touch areas are sanitized after every single use. Just sit back and enjoy.',
          HEADING: 'All equipments and surfaces are sanitized',
        },
        SAFETY_CLEANED_EQUIPMENTS: {
          DESCRIPTION:
            'All the equipments available during your experience are thoroughly sanitized after every single use. Always.',
          HEADING: 'All equipments and surfaces are sanitized',
        },
        SAFETY_CLEANED_VEHICLES: {
          DESCRIPTION:
            'All the vehicles available during your experience are thoroughly sanitized after every single use. Always',
          HEADING: 'All vehicles are sanitized',
        },
        SAFETY_HANDWASH: {
          DESCRIPTION:
            "As a safe experience, you will have access to handwash and sanitation stations throughout your time here. We've got you covered. ",
          HEADING: 'Handwash and sanitation facilities available throughout',
        },
        SAFETY_MASK_BOTH: {
          DESCRIPTION:
            'Masks are mandatory for all guests and staff members in this experience. This helps everyone have a great time with utmost safety.',
          HEADING: 'Everyone wears masks for your safety',
        },
        SAFETY_MASK_DEFAULT: {
          DESCRIPTION:
            'Our partners who have been verified as a Safe Experience follow local regulations in place around face masks. If mandatory, all guests and staff members will wear masks. They take every step possible to ensure your comfort and safety.',
          HEADING: 'Everyone wears masks for your safety',
        },
        SAFETY_MASK_GUEST: {
          DESCRIPTION:
            'Masks are mandatory for all guests in this experience. This helps everyone have a great time with utmost safety.',
          HEADING: 'Everyone wears masks for your safety',
        },
        SAFETY_MASK_STAFF: {
          DESCRIPTION:
            'All staff members at this experience wear face masks to ensure you can have all the fun minus all the worry.',
          HEADING: 'Everyone wears masks for your safety',
        },
        SAFETY_RESTRICTED_CAPACITY: {
          DESCRIPTION:
            'Social distancing guidelines are applicable throughout this experience. Capacity restrictions are in place to ensure social distancing.',
          HEADING: 'Social distancing guidelines are being followed',
        },
        SAFETY_RESTRICTED_CAPACITY_NO_GROUPS: {
          DESCRIPTION:
            "Social distancing guidelines are applicable throughout this experience. Capacity restrictions are in place to ensure social distancing. In the same light, large groups aren't allowed here.",
          HEADING: 'Social distancing guidelines are being followed',
        },
        SAFETY_SOCIAL_DISTANCING: {
          DESCRIPTION:
            'Social distancing guidelines are being adhered to at all times through this experience.',
          HEADING: 'Social distancing guidelines are being followed',
        },
        SAFETY_SOCIAL_DISTANCING_DEFAULT: {
          DESCRIPTION:
            'Social distancing guidelines are being adhered to at all times through this experience. To enforce this, there might be capacity restrictions and group limits in place. Breathe easy.',
          HEADING: 'Social distancing guidelines are being followed',
        },
        SAFETY_SOCIAL_DISTANCING_NO_GROUPS: {
          DESCRIPTION:
            "Social distancing guidelines are being adhered to at all times through this experience. In the same light, large groups aren't allowed here.",
          HEADING: 'Social distancing guidelines are being followed',
        },
        SAFETY_TEMPERATURE_DEFAULT: {
          DESCRIPTION:
            'All guests will have their temperature checked at the start of the experience to ensure safety for all. All staff members are checked for high temperature multiple times a day.',
          HEADING: 'Temperature checks in place',
        },
        SAFETY_TEMPERATURE_GUEST: {
          DESCRIPTION:
            'All guests will have their temperature checked at the start of the experience to ensure safety for all. ',
          HEADING: 'Temperature checks in place',
        },
        SAFETY_TEMPERATURE_STAFF: {
          DESCRIPTION:
            'All staff members are checked for high temperature multiple times. Entry for any member above the prescribed limit is restricted and they are asked to stay at home to recover.',
          HEADING: 'Temperature checks in place',
        },
        SAFETY_TRAINED_STAFF: {
          DESCRIPTION:
            'The staff available throughout the experience are trained as per WHO guidelines and equipped to handle any emergency that comes their way',
          HEADING: 'Staff is trained on WHO guidelines',
        },
      },
      HEADING: 'Safe experiences',
      SUB_HEADING:
        "We are working with our partners all over the globe to implement the best safety standards as per WHO guidelines. All the measures taken on ground have been verified and displayed here. It's time to enjoy the world around us again.",
    },
    NL_DESCRIPTION: {
      CTA_URL:
        'https://www.government.nl/topics/coronavirus-covid-19/covid-certificate',
      TEXT: 'Visitors aged 13 and above must have (digital or printed copy of) a COVID Certificate that shows that you have been vaccinated, received a negative test result, or have recently recovered from COVID-19. Wearing a face mask is mandatory. ',
    },
    SG_DESCRIPTION: {
      CTA_URL: 'https://safetravel.ica.gov.sg/health/vtsg',
      TEXT: 'From 13 October 2021, only fully vaccinated visitors can enter in groups of 2. You will have to show proof of vaccination status, a recent negative COVID-19 test result, and/or any recovery documentation before entry. ',
    },
    SHORT_HEADING: 'Best safety standards in place',
  },
  SAFE_EXPERIENCE_NEW: {
    CTA_TEXT: 'Safety details',
    EXPERIENCE_DESCRIPTION:
      'This experience has been verified to follow best safety standards in compliance with local regulations and WHO',
    FLAG_TEXT: 'Best safety',
    GENERAL_DESCRIPTION:
      'This venue is COVID SECURE and follows all safety measures recommended by the government to ensure that you enjoy a safe and socially-distanced theatre-going experience.',
    GENERAL_DESCRIPTION_V2:
      'We’ve developed the industry-wide See it Safely mark, so that when theatres reopen, you can feel confident and safe knowing that the venue is COVID-secure and will be following the latest government and performing arts guidance.',
    HEADING: 'See it safely',
    MOBILE_HEADING: 'Safe experiences',
    MORE_CURRENCIES: 'More currencies',
    MODAL: {
      DETAILS: {
        CONTACTLESS_MEASURES_DEFAULT: {
          DESCRIPTION: `<li>Only print-at-home tickets and e-tickets will be accepted</li>`,
          HEADING: 'Contactless E-tickets',
        },
        PREVENTIVE_SAFETY_MEASURES_DEFAULT: {
          DESCRIPTION: `<li>Protective gear such as masks are mandatory to enter the venue</li>
            <li>All viewers will be subjected to temperature checks before entering the venue</li>`,
          HEADING: 'Preventive safety measures',
        },
        SANITIZATION_MEASURES_DEFAULT: {
          DESCRIPTION: `<li>Protective gear such as masks are mandatory to enter the venue</li>
            <li>All viewers will be subjected to temperature checks before entering the venue</li>`,
          HEADING: 'Sanitization measures',
        },
        SOCIAL_DISTANCING_MEASURES_DEFAULT: {
          DESCRIPTION: `<li>The venue has been redesigned to minimize contact among all viewers</li>
            <li>Different groups will be seated separately</li>
            <li>Pre-ordering of interval snacks will be facilitated wherever possible</li>
            <li>Cashless and paperless transactions are recommended.</li>
            <li>All viewers are requested to keep their belongings to a minimum since cloakroom facilities may be closed</li>
            <li>Activities such as taking photographs with the artists or autograph signing may be restricted</li>
            `,
          HEADING: 'Social distancing measures',
        },
      },
      HEADING: 'See it safely',
      SUB_HEADING:
        'Wondering about all that’s being done to keep you safe? Find out more about the safety measures and COVID-safe protocols theatres have implemented to ensure your safety.',
    },
    SHORT_HEADING: 'Best safety standards in place',
  },
  SANITARY_ALERT: {
    KEY_TEXT: 'Your safety is our priority',
    TEXT: "We've taken significant steps to improve cleanliness using disinfectants with comprehensive and frequent surface cleaning. Crowds are also being controlled to prevent congestion.",
  },
  SAVE: 'Save {0}%',
  SAVE_PERCENT: 'Save {0}%',
  SAVE_UPTO_PERCENT: 'Save upto {0}%',
  SEE_ALL: 'See all',
  SEARCH: 'Search',
  POPULARITY: 'Popularity',
  PRICE: 'Price',
  SEE_MORE_SHOWS: 'See more shows',
  SELECT_CITY: 'Select city',
  SELECT_SECTION: 'Select section',
  SEE_ALL_PHOTOS: 'See all photos',
  SHOW_CLOSED: 'Show closed',
  SHOW_LESS: 'Show less',
  SHOW_LESS_TEXT: 'Show less',
  SHOW_MORE: 'Show more',
  SHOW_PAGE: {
    ABOUT_SHOW: 'About Show',
    ABOUT_THEATRE: 'About Theatre',
    ADDITIONAL_INFORMATION: 'Additional Information',
    AGE_LIMIT: 'Age Limit',
    AGE_SUITABILITY: 'Age Suitability',
    CRITIC_REVIEW: 'Critic Review',
    ANSWER: ['A-'],
    BLOG_SHOW_PAGE: 'Blog Show Page',
    BLOG_SUMMARY: 'Blog Summary',
    BLOG_THEATRE_PAGE: 'Blog Theatre Page',
    BLOG_THEATRE_SEATING_PLAN: 'Blog Theatre Seating Plan',
    CANCELLATION_AND_REFUNDS: 'Cancellation & Refunds',
    CANCELLATION_POLICY: 'Cancellation Policy',
    CAST_AND_CREW: 'Cast & Crew',
    CLOSING_DATE: 'Closing Date',
    CLOSING_DATE_SPECIAL_OFFER: 'Closing Date Special Offer',
    DURATION: 'Duration',
    FACILITIES_AND_ACCESSIBILITY: 'Facilities & Accessibility',
    FREQUENTLY_ASKED_QUESTIONS: 'Frequently Asked Questions',
    GETTING_THERE: 'Getting There',
    GETTING_THERE_MAP_LINK: 'Getting There Map Link',
    GOOGLE_MAP: 'Google Map',
    HIGHLIGHTS: 'Highlights',
    LISTICLE_SHOW_SUMMARY: 'Listicle Show Summary',
    LISTICLE_SHOW_WHY_WATCH: 'Listicle Show Why Watch',
    OPENING_DATE: 'Opening Date',
    QUESTION: ['Q-'],
    SAFETY_BANNER: 'Safety Banner',
    SHOW_DETAILS: 'Show Details',
    SHOW_TIMINGS: 'Show Timings',
    SHOW_TYPE: 'Show Type',
    SPECIAL_OFFER: 'Special Offer',
    THE_STORY: 'The Story',
    THEATRE_ADDRESS: 'Theatre Address',
    THEATRE_CAPACITY: 'Theatre Capacity',
    THEATRE_NAME: 'Theatre Name',
    TICKETS: 'Tickets',
    TICKETS_REDEMPTION: 'Tickets Redemption',
    TOP_SONGS: 'Top Songs',
    YOUR_TICKETS: 'Your Tickets',
    ABOUT_CONCERT: 'About Concert',
    PROGRAM: 'Program',
  },
  SORT_BY: 'Sort by',
  SPECIAL_OFFER: 'Special Offer',
  THEATRE: 'Theatre',
  THEATRE_PAGE: {
    NEARBY_THEATRES: 'Nearby theatres',
    NOW_PLAYING: 'Now Playing',
    UPCOMING_SHOWS: 'Upcoming Shows',
    PAST_SHOWS: 'Past shows',
    FIND_BEST_SEATS: 'Find best seats',
  },
  TICKET_CAN_BE_CANCELED:
    'You may cancel up to {hours} hours in advance for a full refund.',
  TICKET_CANNOT_BE_CANCELED:
    'This ticket cannot be canceled but can be rescheduled upon request.',
  TICKETS: 'Tickets',
  TIMINGS: 'Timings',
  SPECIAL_PRODUCT_HEADING: 'Most loved',
  SPECIAL_PRODUCT_DESCRIPTORS: {
    LOCAL_GUIDES: 'Expert local guides',
    ENGAGING_STORIES: 'Engaging stories',
    TOP_RATED: 'Top-rated',
  },
  GUIDED_TOUR_REVIEWS: [
    'Our guide Angela was great and full of knowledge of the venue',
    'The guide was very happy to answer questions about the tour',
    'Our guide was great & she even gave us a great lunch recommendation!',
    'The organization and our guide made the experience very pleasant',
    'A lot of educational content, I will surely do it again with my kids',
    'The tour was well organised and just the right time length as well',
    'Our guide really knew his stuff and his passion for history was clear',
    'Good guide and organization! Will recommend!!!',
    "Our guide's little fun facts kept us all engaged and interested",
    "The tour went at a good pace and didn't feel rushed",
    'Our guide Maria was able to get spots for our best viewing point',
    "Our guide enriched our visit beyond what we would've achieved on our own",
    'Detailed and deeply moving tour led by passionate guide',
    'Jeff showed us aspects of the venue that we would have missed otherwise',
  ],
  GUIDES_BANNER: 'Explore like a local with our guest-loved super guides.',
  SPECIAL_GUIDED_TOUR_PRODUCT_SUMMARY: [
    'Taking a guided tour is the best way to get the most from this destination. Our expert guides serve as your local buddies, sharing stories, insider gems, and travel recommendations.',
    "Don't just visit - experience!",
  ],
  HEAR_FROM_OUR_GUESTS: 'Hear from our guests',
  GUIDES: 'Guides',
  TODAY: 'Today',
  TOMORROW: 'Tomorrow',
  TOUR_LIST_HEADING: 'Select your experience',
  TOUR_LIST_SUB_HEADING:
    'Choose from multiple tickets, tours and combo options by verified operators',
  UGC: {
    FOLLOW: 'Follow',
    FOLLOW_IG: 'Follow on Instagram',
    HEADING: 'POV: Your next adventure looks like this',
    SUB_HEADING: 'Curated with 💜 by Headout',
  },
  UNAVAILABLE: 'Unavailable',
  VALIDITY: {
    EXTENDED_BUT_UNKNOWN_VALIDITY:
      'These tickets are valid for an extended duration. The exact details will be present on the ticket.',
    UNTIL_DATE: 'These tickets are valid until {0}.',
    UNTIL_DAYS_FROM_PURCHASE:
      'These tickets are valid for {0} days from the date of purchase.',
    UNTIL_MONTHS_FROM_PURCHASE:
      'These tickets are valid for {0} months from the date of purchase.',
  },
  TABLE_OF_CONTENTS: 'Contents',
  VIEW_LESS: 'View less',
  VIEW_MORE: 'View more',
  WHY_TAKE_THIS_DAY_TRIP: 'Why take this Day Trip?',
  WHY_WATCH: 'Why watch {0}?',
  FIND_ON_MAP: 'Find on map',
  ENTERTAINMENT_MB_LANDING_PAGE: {
    TRUST_BOOSTERS: {
      BOX_OFFICE_TICKETS: {
        NAME: 'Box office tickets',
        DESCRIPTION: 'Instantly confirmed to your phone',
      },
      CHOOSE_YOUR_SEATS: {
        NAME: `Choose your seats`,
        DESCRIPTION: 'The view you want, every time',
      },
      EXCLUSIVE_DEALS: {
        NAME: 'Exclusive deals & discounts',
        DESCRIPTION: `Save big on {0}'s best shows`,
      },
      BOOK_AND_RELAX: {
        NAME: 'Book and relax',
        DESCRIPTION: 'Trusted by {0} million guests and counting',
      },
    },
    YOUR_PICK: 'Your pick',
    BROWSE_BY_CATEGORIES: 'Browse by categories',
    TOP_WEST_END_SHOWS: 'Top West End shows',
    TOP_THEATRE_SHOWS: 'Top {0} shows',
    TOP_SHOWS: 'Top shows',
    SEE_MORE_SHOWS: 'See {0} more shows',
    LAST_MINUTE_TICKETS: 'Last-minute tickets',
    LOVED_BY_MILLIONS: 'Loved by millions',
    TODAY: 'Today',
    TOMORROW: 'Tomorrow',
    SEE_ALL: 'See all',
    GRAB_YOUR_TICKETS: 'Grab your tickets now',
  },
  TAG_NAME: {
    BALLET: 'Ballet',
    CABARET: 'Cabaret',
    CHRISTMAS: 'Christmas',
    CIRCUS: 'Circus',
    CLASSIC: 'Classic',
    COMEDY: 'Comedy',
    COMING_SOON: 'Coming soon',
    CRITICS_CHOICE: "Critics' choice",
    DANCE: 'Dance',
    DISNEY: 'Disney',
    DRAMA: 'Drama',
    ENGLISH_NATIONAL_BALLET: 'English National Ballet',
    ENGLISH_NATIONAL_OPERA: 'English National Opera',
    FANTASY: 'Fantasy',
    HALLOWEEN: 'Halloween',
    IMMERSIVE_THEATRE: 'Immersive Theatre',
    JUKEBOX: 'Jukebox',
    KIDS: 'Kids',
    MAGIC: 'Magic',
    MUSICALS: 'Musicals',
    NEW_ARRIVAL: 'New Arrival',
    OFF_WEST_END: 'Off West End',
    OLIVIER_WINNER: 'Olivier Winner',
    OPERA: 'Opera',
    PANTOMIMES: 'Pantomimes',
    PLAYS: 'Plays',
    ROMANCE: 'Romance',
    SHAKESPEARE: 'Shakespeare',
    STARS_ON_STAGE: 'Stars on Stage',
    THEATRICAL_CONCERT: 'Theatrical Concert',
    TRUE_STORY: 'True Story',
    TONY_WINNER: 'Tony Winner',
    OFF_BROADWAY: 'Off Broadway',
  },
  INVENTORY_UNAVAILABLE: {
    HEADING: 'Uh oh, we’ve a problem',
    MESSAGE: `Sorry! All experiences for this date are either sold out or
    unavailable. But we’ve loads of them available on other dates. All
    you gotta do is be flexible.`,
    CTA: 'View all dates',
  },
  ALL_DATES: 'All dates',
  CATEGORY_HEADER: {
    MAIN_MENU: 'Main menu',
    EXPLORE: 'Explore',
    VISIT: 'Visit',
    THINGS_TO_DO: 'Things to do',
    CITY_ATTRACTIONS: '{0} attractions',
    TOP_THINGS_TO_DO: 'Top things to do',
    CITY_TOURS: '{0} tours',
    CITY_GUIDE: 'City guide',
    ATTRACTIONS: 'Attractions',
    CRUISES: 'Cruises',
    THEMES: 'Themes',
    ABOUT: 'About',
    TOURS: 'Tours',
    SKIP_THE_LINE: 'Skip the Line Tours',
    GUIDED_TOURS: 'Guided Tours',
    NIGHT_TOURS: 'Night Tours',
    FOOD_TOURS: 'Food Tours',
    HISTORY: 'History',
    ARCHITECTURE: 'Architecture',
    DESIGN: 'Design',
    INSIDE: 'Inside',
    COLLECTIONS: 'Collections',
    PAINTINGS: 'Paintings',
    ANIMALS_EXHIBITS: 'Animals and Exhibits',
    HABITATS: 'Habitats',
    CONSERVATION: 'Conservation',
    PROGRAMMES: 'Programmes',
    A2_SIGHTSEEING_CRUISES: 'Sightseeing Cruises',
    A2_DINNER_CRUISES: 'Dinner Cruises',
    A2_EVENING_CRUISES: 'Evening Cruises',
    A2_LUNCH_CRUISES: 'Lunch Cruises',
    A2_YACHT_TOURS: 'Yacht Tours',
    PLAN_YOUR_VISIT: 'Plan Your Visit',
    TIMINGS: 'Timings',
    DIRECTIONS: 'Directions',
    PARKING: 'Parking',
    ROUTES: 'Routes',
    REQUIREMENTS: 'Requirements',
    LOCATION: 'Location',
    RULES: 'Rules',
    FAQs: 'FAQs',
    ENTRANCES: 'Entrances',
    RESTAURANTS: 'Restaurants',
    FACTS: 'Facts',
    TIPS: 'Tips',
    MAP: 'Map',
    RIDES: 'Rides',
    SHOWS: 'Shows',
    DINING: 'Dining',
    SHOPPING: 'Shopping',
    EVENTS: 'Events',
    HALLOWEEN: 'Halloween',
    CHRISTMAS: 'Christmas',
    NEW_YEARS_EVE: "New Year's eve",
    TRAVEL_GUIDE: 'Travel guide',
    TRIP_PLANNER: 'Trip planner',
    ITINERARY: 'Itinerary',
    TRAVEL_TIPS: 'Travel tips',
    WEATHER: 'Weather',
    BEST_TIME_TO_VISIT: 'Best time to visit',
    JANUARY: 'January',
    FEBRUARY: 'February',
    MARCH: 'March',
    APRIL: 'April',
    MAY: 'May',
    JUNE: 'June',
    JULY: 'July',
    AUGUST: 'August',
    SEPTEMBER: 'September',
    OCTOBER: 'October',
    NOVEMBER: 'November',
    DECEMBER: 'December',
    WHERE_TO_EAT: 'Where to eat',
    WHERE_TO_STAY: 'Where to stay',
    NEIGHBOURHOODS: 'Neighbourhoods',
    FAMILY_TRAVEL: 'Family travel',
    TRANSPORTATION: 'Transportation',
    FESTIVALS_EVENTS: 'Festivals and events',
  },
  BREADCRUMBS: {
    HOME: 'Home',
    TRAVEL_GUIDE: 'Travel Guide',
    THINGS_TO_DO: 'Things to do in {0}',
    SHOW_NAME_TICKETS: '{0} - Tickets',
    LTT_HOME: 'London Theatre Tickets',
    LTT_VENUE_PAGE_HOME: 'Theatres in London',
    BROADWAY_HOME: 'Broadway Show Tickets',
    BROADWAY_VENUE_PAGE_HOME: 'Broadway Theatres',
    VIENNA_CONCERT_HOME: 'Vienna Concert Tickets',
    VIENNA_CONCERT_VENUE_PAGE_HOME: 'Concert Halls in Vienna',
    ATTRACTIONS: 'Attractions',
    A2_SIGHTSEEING_CRUISES: 'Sightseeing Cruises',
    A2_DINNER_CRUISES: 'Dinner Cruises',
    A2_EVENING_CRUISES: 'Evening Cruises',
    A2_LUNCH_CRUISES: 'Lunch Cruises',
    A2_YACHT_TOURS: 'Yacht Tours',
    LONDON_THEATRE_NEWS: 'London Theatre News',
    BROADWAY_SHOW_NEWS: 'Broadway Show News',
    NEWS_PAGE: 'News Page',
    REVIEWS_PAGE_BANNER_HEADING: '{0} reviews',
  },
  AMENITIES: {
    Restaurant: 'Restaurant',
    Cafe: 'Cafe',
    'Dedicated Parking': 'Dedicated Parking',
    Elevators: 'Elevators',
    Lift: 'Lift',
    'Wheelchair Accessible': 'Wheelchair Accessible',
    Cloakroom: 'Cloakroom',
    'Air Conditioning': 'Air Conditioning',
    WiFi: 'WiFi',
    Bar: 'Bar',
    'Water Fountain': 'Water Fountain',
    Washrooms: 'Washrooms',
    Toilets: 'Toilets',
    Binoculars: 'Binoculars',
    'Adapted Toilets': 'Adapted Toilets',
    'Accessible Toilets': 'Accessible Toilets',
    'Assistive Listening System': 'Assistive Listening System',
    'Hearing Assistance': 'Hearing Assistance',
    'Booster Cushions': 'Booster Cushions',
    Shop: 'Shop',
    'Souvenir Shop': 'Souvenir Shop',
    'Guide Dogs': 'Guide Dogs',
    'Baby Changing Facilities': 'Baby Changing Facilities',
    'Security Check': 'Security Check',
    Ramp: 'Ramp',
    'Level Access': 'Level Access',
  },
  CALENDAR: {
    FOOTNOTE: '* All prices are in {0}',
    PICK_DATE: 'Pick a date',
  },
  REVIEW_LOC: {
    VIEW_ORIGINAL: 'View original review in {0}',
    VIEW_ORIGINAL_NO_LANG: 'This review is translated. Show original review.',
    TRANSLATE: 'Translate review in {0}',
    LANGUAGES: {
      EN: 'English',
      ES: 'Spanish',
      FR: 'French',
      IT: 'Italian',
      DE: 'German',
      PT: 'Portuguese',
      NL: 'Dutch',
    },
  },
  SHOW_PAGE_V2: {
    READ_MORE_REVIEWS: 'Read more reviews',
    SHOW_MORE_REVIEWS: 'Show more reviews',
    MUST_DO_EXP: 'Must-do experience',
    ALL_PHOTOS: 'All photos',
    MORE_DATES: 'More dates',
    SELLING_OUT_FAST: 'Selling out fast',
    SELECT_TIME_SLOT: 'Select a time slot',
    SELECT_TIME_SLOT_ERROR: 'Select a time slot to continue',
    PRICE_STARTING_FROM: 'Seats price starting from',
    SELECT_SEATS: 'Select seats',
    READ_MORE: 'Read more',
    RATINGS_AND_REVIEWS: 'Ratings & reviews',
    READ_DETAILED_REVIEWS: 'Read detailed reviews',
    INTERVAL: 'Interval',
    TWO_PART_SHOW: 'Two-part show',
    UNTIL_DATE: 'Until {0}',
    AVAILABLE_TIME: 'Available time slot',
    EXPERIENCE_AVAILABLE_ONLY_AT:
      'This experience is available at the below time only',
    CONTENT_TABS: {
      ABOUT: 'About',
      VENUE: 'Venue',
      TICKETS: 'Tickets',
      Reviews: 'Reviews',
    },
    SELECT_SHOW_TIMMING: 'Select your showtime',
    ONLY_ONE_SLOT: 'Only 1 time slot available on the selected date',
    SIMILAR_SHOWS: 'You might also like',
    CONTENT_SECTION_HEADERS: {
      STORYLINE: 'Storyline',
      WHAT_CRITICS_THINK: 'What the critics think',
      FREQUENTLY_ASKED_QUESTIONS_ABOUT: 'Frequently asked questions about {0}',
      AGE_SUITABILITY_AND_GUIDELINES: 'Age & content guide',
      CAST_AND_CREATIVES: 'Cast & creatives',
    },
    TICKETS_UNAVAILABLE: 'Tickets unavailable',
    TICKETS_UNAVAILABLE_SUBTEXT:
      'Luckily, we have plenty more to choose from right here.',
    MUST_SEE_SHOWS: 'More must-see shows',
    SEE_ALL_SHOWS: 'See all shows',
  },
  HOHO: {
    HOHO: 'Hop On Hop Off Tours',
    BUS_ROUTES_DETAILS: 'Bus routes',
    TOUR_DETAILS: 'Tour details',
    ROUTES_SCHEDULES: 'Routes & schedules',
    VALIDITY: 'Valid for 1 day',
    VALIDITY_NOTE:
      'Your experience is valid for {0} calendar day(s). If you book your ticket for 6th May, 2023- your ticket will be valid till 6th May 2023, 9:00 PM PST.',
    BESTSELLER: 'Bestseller',
    SEE_ALL: 'See all',
    TIMINGS: 'TIMINGS',
    FREQUENCY: 'FREQUENCY',
    DURATION: 'DURATION',
    STARTING_LOCATION: 'Starting location',
    CALENDAR_FOOTNOTE: '* All prices are in {0}',
    TOUR_TIMINGS: 'Tour timings',
    TOUR_FREQUENCY: 'Tour frequency',
    GOT_IT: 'Got it',
    SELECT_DATE: 'Select a date',
    SELECT_TOUR: 'Select tour type',
    VIEW_TOUR_DETAILS: 'View tour details',
    BUS_DETAILS: 'MORE DETAILS',
    VIEW_ROUTES: 'View all routes',
    BUS_ROUTES: 'BUS ROUTES',
    ATTRACTIONS_PREVIEW: 'Fastest way to view {0}+ top attractions including',
    ROUTES: 'Routes',
    COMBO_SUBTITLE: 'Save more with',
    COMBO_TITLE: 'Combo deals on your Hop-on Hop-off tour',
    COMBO_DWEB_TITLE: 'Save more with combo deals on your Hop-on Hop-off tour',
    STOPS_AND_ATTRACTIONS: 'Stops & nearby attractions',
    HOP_ON_OFF_AT: 'Hop on and hop off at any of these {0} stops',
    TIMINGS_FREQUENCY: 'Timings & frequency',
    TOUR_DURATION: 'Tour duration',
    EVERY_X_MINS: 'Every {0}',
    VIEW_LOCATION: 'View location',
    BANNER_SUBTEXT:
      'Most convenient and quickest way to see all top attractions in the city.',
    TOP_ATTRACTIONS: 'Top attractions covered',
    TRUST_BOOSTERS: {
      LAST_MIN: {
        NAME: 'Last minute booking',
        DESCRIPTION: 'Instantly confirmed to your phone',
      },
      COST_EFF: {
        NAME: 'Cost-effective exploring',
        DESCRIPTION: 'Multiple landmarks, one ticket',
      },
      DAILY_RIDES: {
        NAME: 'Daily unlimited rides',
        DESCRIPTION: 'Explore the city at your pace',
      },
      BOOK_RELAX: {
        NAME: 'Book and relax',
        DESCRIPTION: 'Trusted by {0} million guests',
      },
    },
  },
  BANNER_DESCRIPTORS: {
    EXPERT_MULTILINGUAL_GUIDES: 'Expert multilingual guides',
    STL: 'Skip-the-line access',
    PERSONALIZED: 'Personalized experience',
    LOCAL_FOOD: 'Local food & culture',
    MULTILINGUAL_GUIDES: 'Multilingual guides',
    CULINARY_EXPERTS: 'Culinary experts',
    SIGHTSEEING: 'Sightseeing & onboard entertainment',
    SIGHTSEEING_MEAL: 'Sightseeing & onboard meal options',
    MULTILINGUAL_GT: 'Multilingual guided tours',
    AUDIOGUIDE_COMMENTARY: 'Audioguide & commentary available',
    ALL_TOP_ATTRACTIONS_COVERED: 'All top attractions covered',
    REG_SCHEDULE_UNLIMITED_RIDES: 'Regular schedule. Unlimited rides',
    LAST_MIN_AVAIL_INSTANT_CONFIRMATION:
      'Last minute availability. Instant confirmation',
    MULTI_ROUTES: 'Multiple route options',
    HASSLE_FREE: 'Hassle-free booking',
    FREQUENT_DEPARTURES: 'Frequent departures',
    ONBOARD_AMENITIES: 'Best onboard amenities',
    FLEXI_TRAVEL: 'Flexible travel on multiple routes',
    FLEXI_EXCHANGE_CANCEL: 'Flexible exchanges and cancellations',
    EXTRA_DISCOUNT: 'Extra discounts for youths, seniors & families',
    PREPAID: 'Prepaid convenience',
    EASY_ACTIVATION: 'Easy activation',
    INSTANT_CONNECTIVITY: 'Instant connectivity with global coverage',
    ROUND_TRIP: 'Round-trip transfers',
    CULTURAL_EXPERIENCE: 'Authentic cultural experiences',
    GUARANTEED_SEAT: 'Guaranteed seat',
    ONBOARD_WIFI: 'Onboard Wi-Fi & charging ports',
    LUGGAGE_ASSISTANCE: 'Luggage assistance',
    TOP_ATTRACTIONS: 'Top attractions included',
    FREQ_DEP_MULTI_ROUTE: 'Frequent departures on multiple routes',
    CHARGING: 'Charging station',
    WIFI: 'Wi-Fi access',
    FnB: 'F&B availability',
    LUXURY: 'Luxury experience',
    FOOD_DRINK: 'Food & drinks',
    PANAROMIC: 'Panoramic views of top attractions',
    PANAROMIC_2: 'Panoramic views',
    PANAROMIC_3: 'Panoramic, open-top views',
    BEVERAGES: 'Onboard beverages',
    GOURMET: 'Gourmet dinner',
    GOURMET_2: 'Gourmet dinner & drinks',
    NIGHTTIME: 'Stunning nighttime views',
    ROMANTIC: 'Romantic atmosphere',
    TRANSFERS: 'Transfers included',
    CERTIFIED_OP: 'Certified operations',
    BIRD_EYE: "Bird's-eye view",
    MULTI_JUMP: 'Multiple jump times',
    EXTREME_ADV: 'Extreme adventure',
    PHOTO_OP: 'Scenic photo opportunities',
    TOP_ATTRACTIONS_COVERED: 'Top attractions covered',
    LOCAL_CULTURE: 'Experience local culture',
    EXCLUSIVE_DISC: 'Exclusive discounts',
    EFFICIENT_PUBLIC_TRANSIT: 'Efficient public transit',
    FREQUENT_SERVICE: 'Frequent service',
    PREBOOKING: 'Easy pre-booking',
    LOCAL_CULTURE_IMM: 'Local cultural immersion',
    DISCOVER_HIDDEN: 'Discover hidden places',
    CITY_SIGHTSEEING: 'City sightseeing',
    OFFBEAT_ADV: 'Offbeat adventure',
    DRINK_DEALS: 'Drink deals',
    OFFBEAT_EXP: 'Offbeat experience',
    MULTIPLE_VARIETIES: 'Multiple varieties included',
    HIDDEN_GEMS: 'Hidden gems & offbeat attractions',
    EXPLORE_LOCAL: 'Explore local neighbourhoods',
    CUSTOMIZED_TREATMENTS: 'Customized treatments',
    TRAINED_THERAPISTS: 'Trained therapists',
    HOLISTIC_WELLNESS: 'Holistic wellness',
    FLEXIBLE_DURATION: 'Flexible duration',
    MULTI_BOAT: 'Multiple boat options',
    SCENIC_ROUTES: 'Scenic routes',
    HANDS_ON: 'Hands-on learning',
    CULTURAL_CULINARY: 'Cultural & culinary insights',
    EXPERT_HOSTS: 'Expert hosts',
    ADVENTURE_EXPERIENCE: 'Adventure experience',
    EQUIPMENT: 'Equipment included',
    SCENIC_FLYOVERS: 'Scenic flyovers',
    LIVE_COMMENTARY: 'Live commentary',
    BEST_AERIAL_VIEWS: 'Best aerial views',
    SAFE_CERT_OP: 'Safe & certified operations',
    HANDPICKED: 'Handpicked tours',
    EFFORTLESS: 'Effortless planning',
    EXPERT_GUIDES: 'Expert guides. Unique insights',
    ALL_ATTR_ONE_CARD: 'All attractions, one card',
    MULTI_CARD: 'Multiple card options',
    COST_EFF: 'Cost-effective and convenient',
    BEST_PRICES: 'Best prices',
    MULTI_TRANSPORT: 'Multiple transport options',
  },

  CAT_SUBCAT_PAGE: {
    ALL: 'All',
    POPULAR_SUBCATEGORY: 'Popular {0}',
    TOP_CATEGORY: 'Top {0}',
    ATTRACTIONS: 'Attractions',
    ALL_SUBCATEGORY: 'All {0}',
    BROWSE_BY_CATEGORIES: 'Browse by categories',
    TICKETS_FROM: 'Tickets from',
    PRICE_LOW_HIGH: 'Price (low to high)',
    PRICE_HIGH_LOW: 'Price (high to low)',
    FILTERS: 'Filters',
    RESET: 'Reset',
    APPLY: 'Apply',
  },
  COOKIE_BANNER: {
    DESKTOP:
      'Headout uses cookies to enhance your website journey one byte at a time.',
    MOBILE: 'Cookies at work.',
    LEARN_MORE: 'Learn more.',
  },
  SHOW_UNAVAILABLE: 'Show unavailable',
  NEWS_PAGE: {
    FEATURED_NEWS: 'Featured news',
    ALL_NEWS: 'All news',
    MORE_READS: 'More reads',
    LOAD_MORE: 'Load more',
    TRAILERS: 'Trailers',
    GET_YOUR_TICKETS: 'Get your tickets',
    POPULAR_SHOWS: 'Popular shows',
    FEATURED: 'Featured',
    RECENT_NEWS: 'Recent news',
    SHOW_MORE_ARTICLES: 'Show more articles',
    COLLAPSE_ALL: 'Collapse all',
    REVIEWS: 'Reviews',
    SHOW_ALL_REVIEWS: 'Show all reviews',
    MAILER: {
      HEADING: 'Best theatre deals & news straight to your inbox',
      SUBHEADING: `Join our mailing list to get the latest news & hottest deals on theatre shows.`,
    },
  },
  REVIEWS_PAGE: {
    BANNER_HEADING: '{0} reviews',
    REVIEWS_COUNT: '{0} reviews',
    AWESOME: 'Awesome',
    GOOD: 'Good',
    AVERAGE: 'Average',
    POOR: 'Poor',
    TERRIBLE: 'Terrible',
    REVIEW_BY_HEADOUT: 'Here’s what we think: Review by Headout',
    WHAT_PEOPLE_ARE_SAYING: 'What people are saying',
    MOST_RELEVANT: 'Most relevant',
    RATING_HIGH_TO_LOW: 'Rating: High to Low',
    RATING_LOW_TO_HIGH: 'Rating: Low to High',
    CRITIC_REVIEWS: 'Critic reviews',
    USER_REVIEWS: 'User reviews',
    CONTRIBUTORS_REVIEW: 'Contributor’s review',
  },

  AIRPORT_TRANSFER: {
    PRIVATE_TRANSFERS: 'Private transfers',
    SHARED_TRANSFERS: 'Shared transfers',
    BOOKING_STEPS: {
      BOOK_ONLINE: {
        TITLE: 'Book online',
        DESCRIPTION:
          'Enjoy a fast, and convenient booking experience by booking your tickets online.',
      },
      INSTANT_CONFIRMATION: {
        TITLE: 'Instant confirmation',
        DESCRIPTION:
          'Get instant confirmation on booking your airport transfer ticket.',
      },
      CONVENIENT_PICKUP: {
        TITLE: 'Convenient pickup',
        DESCRIPTION:
          'Avoid the hassle of long queues as your transfer vehicle will be waiting for you at your selected pickup point.',
      },
      DROP_OFF: {
        TITLE: 'Drop off',
        DESCRIPTION:
          'Get to reach your destination in a fast and comfortable manner.',
      },
    },

    SHARED_TRANSFERS_IN: 'Shared transfers in {0}',
    SHARED_TRANSFERS_DESCRIPTION:
      ' A comfortable cost-effective transportation option in for multiple passengers traveling to or from an airport.',
    PRIVATE_TRANSFERS_IN: 'Private transfers in {0}',
    PRIVATE_TRANSFERS_DESCRIPTION:
      ' A convenient, personalized transportation service for individuals or small groups, offering a comfortable and direct ride to and from an airport.',
    ANYWHERE_IN_THE_CITY: 'Anywhere in the city',
    DESCRIPTORS: {
      MULTIPLE_VEHICLE: 'Multiple vehicle options',
      FREE_CANCELLATION: 'Free cancellation',
    },
    SEAMLESS_TRANSFERS: 'Seamless transfers, trusted worldwide',
    PICK_THE_BEST_AIRPORT_TRANSFER: 'Pick the best airport transfer for you',
    HASSLE_FREE_TRANSFER: 'Hassle-free airport transfer experience',

    // New experiment (AT LP)
    EFFORT_LESS_AIRPORT_TRANSFERS: 'Effortless airport transfers in {0} by',
    BUSES: 'buses',
    TRAINS: 'trains',
    PRIVATE_TAXIS: 'private taxis',
    BUS_TRAIN: 'Bus/Train',
    PRIVATE_TAXI: 'Private taxi',

    FROM: 'From',
    SELECT_PICKUP: 'Select pick-up',
    TO: 'To',
    SELECT_DROPOFF: 'Select drop-off',
    ENTER_DESTINATION: 'Enter your destination',
    ENTER_PICKUP: 'Enter pick-up location',
    PICKUP_DATE: 'Pick-up date',
    SELECT_DATE: 'Select date',
    PICKUP_TIME: 'Pick-up time',
    SELECT_TIME: 'Select time',
    GUEST: 'Guest',
    GUESTS: 'Guests',
    ADD_GUESTS: 'Add guests',
    EXPLORE_TRANSFERS: 'Explore transfers and more',
    COMBOS_AND_EXTRAS: 'Combos and extras',

    YOUR_RIDE_DOORSTEP: 'Your ride, at your doorstep',
    COMFORTABLE_RIDE:
      'Book a comfortable private taxi to and from major airports in {0}',
    TRUSTED_DRIVERS: 'Trusted drivers',
    RIDES_24_7: 'Rides available 24/7',
    FLIGHT_TRACKING: 'Flight tracking',
    KNOW_MORE: 'Know more',
    RESERVE_YOUR_RIDE: 'Reserve your ride',
    TRANSFER_OPTIONS: 'Transfer options found',
    TRANSFERS_AVAILABLE_PLURAL: '{0} transfers available',
    TRANSFERS_AVAILABLE_SINGULAR: '1 transfer available',

    TRUSTED_PARTNERS: '35+ trusted partners, {0}',
    WORLDWIDE: 'worldwide',
    COMFORTABLE_RIDES: 'Comfortable rides',
    FREQUENT_DEPARTURES: 'Frequent departures',
    MULTIPLE_TRANSPORT_OPTIONS: 'Multiple transport options',
    AND_MANY_MORE: 'And many more',

    ADD_PICKUP_LOCATION: 'Add pick-up location',
    ADD_DROPOFF_LOCATION: 'Add drop-off location',
    SELECT_PICKUP_DATE_AND_TIME: 'Select pick-up date and time',
    SELECT_A_DATE: 'Select a date',

    TRAIN_STATION: 'Train station',
    BUS_STOP: 'Bus stop',

    ADD_RETURN: 'Add return',
    HEADS_UP: 'Heads up!',
    ADDED_RETURN_TRIP: 'You’ve added a return trip!',
    RETURN_NOTE:
      'Please note, we’ll show the return journey details in the next steps.',
    OKAY_I_UNDERSTAND: 'Okay, I understand',

    SERVING_GUESTS: 'Serving {0} in {1} around the globe',
    GUESTS_COUNT: '220K+ guests',
    CITIES_COUNT: '55+ cities',
    PARNTER_WITH_BEST:
      'We partner with only the best, to make sure you have an unforgettable experience. Let us handle the details — while you sit back, unwind, and enjoy the ride',
  },
  PC_EXP: {
    SHOW_INCL: 'Show inclusions & important info',
    CLEAR: 'Clear',
    ALL_EXPERIENCES: 'All experiences',
    FILTER_BY_CATEGORIES: 'Filter by categories',
    CATEGORIES: 'Categories',
    COMBOS: {
      HEADING: 'Everything good comes in pairs. Just like our saver combos.',
      DESCRIPTOR_1: 'Money saving deals',
      DESCRIPTOR_2: 'Plan your trip better',
    },
  },
  TOP: 'Top {0}',
  VIEW_CALENDAR: 'View calendar',
  SHOWS_CALENDAR: 'Shows calendar',
  IN: 'in',
  SEATS: 'Seats',
  CONTENT_PAGE: {
    CONTENT_GUIDE: 'Content Guide',
    QUICK_INFORMATION: 'Quick Information',
    PEAK: 'Peak',
    OFF_PEAK: 'Off Peak',
    ADDRESS: 'ADDRESS',
    RECOMMENDED_DURATION: 'RECOMMENDED DURATION',
    VISITORS_PER_YEAR: 'VISITORS PER YEAR',
    NUMBER_OF_ENTRANCES: 'NUMBER OF ENTRANCES',
    EXPECTED_WAIT_TIME_STANDARD: 'EXPECTED WAIT TIME - STANDARD',
    EXPECTED_WAIT_TIME_SKIP_THE_LINE: 'EXPECTED WAIT TIME - SKIP THE LINE',
    UNESCO_YEAR: 'UNESCO YEAR',
    ARCHITECTURE_STYLE: 'ARCHITECTURE STYLE',
    TIMINGS: 'Timings',
    SIZE_HEIGHT: 'SIZE/HEIGHT',
    TICKETS: 'TICKETS',
    FUN_FACTS: 'Fun facts',
    DID_YOU_KNOW: 'Did you know?',
    MORE_INTERESTING_FACTS: 'More interesting facts',
    PLAN_YOUR_VISIT: 'Plan your visit',
    ALSO_KNOWN_AS: 'Also Known As',
    FOUNDED_ON: 'Founded On',
    FOUNDED_BY: 'Founded By',
    FROM: 'From',
    CLOSED_TODAY: 'Closed today',
    TODAY: 'Today',
    CLOSED: 'Closed',
    OPEN: 'Open',
    LAST_ADMISSION: 'Last Admission',
    DAYS: 'Days',
    BEST_TIME_TO_VISIT: 'Best time to visit the',
    WEEKDAY_VS_WEEKEND: 'Weekday vs Weekend',
    PEAK_VS_LOW_SEASON: 'Peak season vs low season',
    PLEASANT: 'Pleasant',
    CROWDED: 'Crowded',
    VERY_CROWDED: 'Very Crowded',
    LOW_SEASON: 'Off Season',
    MID_SEASON: 'Moderate Season',
    PEAK_SEASON: 'Peak Season',
    TO: 'TO',
    LOWER_TO: 'to',
    LAST_ENTRY: 'last entry',
    SUBATTRACTIONS: 'Sub-Attractions',
    INCLUDED_WITH_TICKETS: 'Included with {0} tickets',
    INCLUDED_WITH_SOME_TICKETS: 'Included with some {0} tickets',
    EXPLORE_ALL_POI_TICKETS: 'Explore {0} & {1} tickets & tours',
    EXPLORE_PARENT_TICKETS: 'Explore {0} tickets & tours',
    SELECT_YOUR_EXPERIENCE: 'Select your experience',
    OPERATING_HOURS: 'Operating hours',
    SEE_MORE: 'See more',
    MORE_INSIGHTS: 'More insights',
  },
  OFF_PERCENT: '{0}% off',
  THEATRE_LANDING_PAGE: {
    SEAT_PLAN: 'Seat plan',
    CAPACITY: 'Capacity: {0} seats',
    WHATS_ON: `What's on`,
    CATEGORIES: 'Categories',
  },
  NEXT: 'Next',
  PREVIOUS: 'Previous',
  ITINERARY: {
    TAB: 'Itinerary',
    HEADING: 'Experience itinerary',
    VIEW_ITINERARY: 'View Itinerary',
    VIEW_DETAILS: 'View details',
    PASSES_BY_SECTION_HEADING: 'Passes by',
    SUB_SECTION_HEADING: {
      HIGHLIGHTS: 'Highlights',
      NEARBY_THINGS_TO_DO: 'Nearby things to do',
      THINGS_TO_DO: 'Things to do',
    },
    STOP_CARD: {
      TITLE: {
        START: 'START POINT',
        END: 'END POINT',
        STARTING_POINT: 'STARTING POINT',
        STOP: 'STOP',
        ENDING_POINT: 'ENDING POINT',
        PASSING_BY: 'PASSING BY',
      },
      MULTI_POINTS_AVAILABLE: {
        START: '{0} starting points available',
        END: '{0} ending points available',
      },
    },
    GET_DIRECTION: 'Get direction',
    DESCRIPTORS: {
      DURATION: {
        FULL: '{0} hours {1} mins',
        WITHOUT_HOURS: '{0} mins',
        WITHOUT_MINS: '{0} hours',
      },
      ATTRACTIONS_COUNT: '{0} attractions',
      ACTIVITIES_COUNT: '{0} activities',
      TOTAL_DURATION: 'TOTAL DURATION',
      FREQUENCY: 'FREQUENCY',
      FIRST_DEPARTURE: 'FIRST DEPARTURE',
      LAST_DEPARTURE: 'LAST DEPARTURE',
      FIRST_DEPARTURE_TIME: 'FIRST DEPARTURE TIME',
      LAST_DEPARTURE_TIME: 'LAST DEPARTURE TIME',
      MODE_OF_TRANSPORT: 'MODE OF TRANSFER',
      FOOD_AND_DRINKS: 'FOOD & DRINKS',
    },
    WALK_DURATION: '{0} walk',
    MORE: '{0} more',
    TIMELINE_VIEW: 'Timeline',
    MAP_VIEW: 'Map',
    MAP_OVERLAY: 'Use CTRL/⌘ + scroll to move the map',
    VIEW_EXPERIENCE_MAPPED_OUT: 'View your experience all mapped out.',
    OPEN_MAP_VIEW: 'Open map',
    START_POINT_SAME_AS_END_POINT:
      'Your ending point would be same as your start point',
  },
  CRUISES: {
    BANNER_HEADING: 'Cruise Through {0} - Discover, Dine & Relax ',
    FILTERS: {
      ALL: 'All',
      SIGHTSEEING: 'Sightseeing',
      LUNCH: 'Lunch',
      DINNER: 'Dinner',
      COMBOS: 'Combos',
    },
    MAP_OVERLAY: 'Click or tap to move around the map',
    SIGHTS_COVERED: 'Sights covered',
    MENU: 'Menu',
    BOARD_AT: 'Board at {0}',
    BOARDING_POINTS_AVAILABLE: '{0} available',
    VIEW_POPULAR_SIGHTS: 'View popular sights like {0} {1}',
    X_BOARDING_POINTS: '{0} boarding points',
    BOARDING_POINT: 'Boarding point',
    BOARDING_POINTS: 'Boarding points',
    LANDMARKS: 'Landmarks',
    GET_DIRECTIONS: 'Get directions',
    FOOD_MENU: 'Food menu',
    X_PAGE: '{0} page',
    X_PAGES: '{0} pages',
    MORE: '+{0} more',
    PDF: {
      ZOOM_IN: 'Zoom in',
      ZOOM_OUT: 'Zoom out',
      FIT_TO_WIDTH: 'Fit to width',
      RESET_ZOOM: 'Reset zoom',
    },
    COMBO_HEADING: 'Experience more for less: Combo deals & city cards',
  },
  CUSTOM_CTA_EXPERIMENT_TEXT: 'Check availability', // just for types
  SELECT_AN_OPTION: 'See options',

  PRIVATE_AT_LANDING_PAGE: {
    CITY_AIRPORT_TRANSFERS: '{0} Airport Transfers',
    BANNER_SUBTEXT:
      'Enjoy stress-free travel with a reliable, pre-booked airport transfer.',
    BEST_PRICES: 'Best prices, no hidden fees',
    BEST_PRICES_SUBTEXT:
      'Fixed pricing with no surprises, offering you competitive rates for premium service.',
    FREE_WAIT_TIME: 'Free wait time, no rush',
    FREE_WAIT_TIME_SUBTEXT:
      'Enjoy a free waiting time of 45 minutes while you claim your baggage',

    COMFORTABLE_RIDE: 'Comfortable ride',
    COMFORTABLE_RIDE_SUBTEXT:
      'Ride in style with a fleet of top-class vehicles, driven by handpicked and trained drivers.',

    FREE_CANCELLATION: 'Free cancellation',
    FREE_CANCELLATION_SUBTEXT:
      'Plans changed? No problem. Enjoy free cancellation and 24/7 support.',

    EXCELLENCE_HEADING: 'A track record of excellence and trust',
    ACTIVE_CITIES: 'active cities, worldwide',
    CUSTOMERS_SERVED: 'customers served',
    YEARS_OF_SERVICE: 'years of excellent service',

    FIND_YOUR_RIDE: 'Find your perfect ride',
    FIND_YOUR_RIDE_SUBTEXT:
      'From family-sized vans to luxury sedans, pick a car that fits your luggage and group size',
  },
  VIEW_ALL_EXPERIENCES: 'View all experiences',
  DAY_TRIPS: {
    BANNER: {
      SUBTEXT: `Skip the stress of planning. We cover every detail - curated itinerary, comfortable transfers and expert guides. It's the best way to see the sights without the hassle.`,
    },
    WHY_WITH_HO: {
      TITLE: 'Book stress free with Headout',
      CURATED_EXPERIENCES: {
        TITLE: 'Simple decision making with carefully chosen experiences',
        DESCRIPTION:
          'We handle every detail from transfers to meals so you have a memorable trip.',
      },
      EXPERT_GUIDES: {
        TITLE: 'Experience the trip through the eyes of a local expert',
        DESCRIPTION:
          'Our guides bring rich insights and memorable stories to every journey.',
      },
      FLEXI_CANCELLATION: {
        TITLE: 'Enjoy peace of mind with maximum flexibility ',
        DESCRIPTION:
          'Life happens, but we’ve got you covered with free cancellations or easy reschedule.',
      },
      GUEST_SUPPORT: {
        TITLE: '24x7 support available for you at all times',
        DESCRIPTION:
          'Travel with peace of mind knowing that our team is just a call or message away.',
      },
    },
    JUMP_LINK_ITEMS: {
      TESTIMONIALS: {
        TITLE: '{0}M+ travellers love us',
        SUBTITLE_WITH_REVIEWS: 'Read real reviews from real travellers',
        SUBTITLE_WITHOUT_REVIEWS: 'Delivering best in class experience',
      },
      HEADOUT_VERIFIED: {
        TITLE: 'Why book with us?',
        SUBTITLE: 'Hassle-free trips. Find out how.',
      },
    },
    NEAR_BY_DESTINATIONS_TITLE: 'Explore more destinations from {0}',
  },
  REVIEW_SECTION: {
    HEADING: 'Millions love heading out with us',
  },
  FLEXIBLE_CANCELLATION: {
    TOOLTIP_TEXT:
      'Get full refund for a small fee if you cancel 24 hours before the experience start time.',
    KNOW_MORE: 'Know more',
    FULL_REFUND: 'Full refund',
    INSTANT: 'Instant',
    NO_QUESTIONS: 'No questions asked',
    TNC: 'Terms and conditions',
    ELIGIBILITY:
      'Eligibility: The Flexible cancellation plan must be selected and purchased at the time of booking.',
    CANCELLATION_DEADLINE:
      'Cancellation deadline: Booking can be cancelled up to 24 hours before the experience start time.',
    REFUND_AMOUNT:
      'Refund amount: You’ll receive a refund of the original paid booking amount (minus the cancellation protection charge). ',
    NON_TRANSFERABLE: 'Non-Transferable: Valid for this booking only.',
    GUIDELINES:
      'Guidelines: This plan becomes void if the guest redeems or uses any part of the ticket before cancellation. In such cases, standard cancellation policies apply, and no additional refund will be given.',
  },
  AGGREGATED_COUNTRIES:
    'Guests from <strong>{0}</strong> and <strong>over {1} countries</strong> have loved this experience',
  TRUST_TOOLTIP_HEADER: 'How do we collect reviews?',
  TRUST_TOOLTIP_CONTENT:
    "Reviews are collected from real Headout users who've booked and completed their experience with us.",
  REVIEW_SECTION_HEADER: 'What our guests say',
  TRUST_VB_TAG: 'Verified booking',
  SNAPSHOTS_SECTION_HEADER: 'Snapshots from our guests',
  SEE_MORE_RESPONSES: 'See more responses',
  SEE_ALL_RESPONSES: 'See all responses',
  HIDE_RESPONSES: 'Hide responses',
  GUEST_QNA: 'Guest Q&A',
  ASKED_AND_ANSWERED: 'Asked & answered',
  VIEW_ALL_QNA: 'View all Q/As',
  EXPLORE_ALL: 'Explore all',
  EXPLORE_MORE_QUESTIONS: 'Explore more questions',
  GUESTS: 'guests',
  RESPONSES: 'responses',

  FILTERS: {
    DATE_SELECTION: 'Date selection',
    DEALS: 'Deals',
    ENTRY_TICKETS: 'Entry tickets',
  },

  BOOSTERS: {
    TOP_RATED: 'Top rated',
    SPECIAL_DEAL: 'Special deal',
  },

  OTHER_EXPERIENCES: 'Other experiences',

  POI_COLLECTIONS_SECTION: {
    MAKE_THE_MOST_OF_CITY: 'Make the most of {0}',
    TOP_EXPERIENCE_PICKS: 'Top experience picks',
    EXPERIENCES: '{0}+ experiences',
    CITY_YOUR_WAY: '{0}, your way!',
    DISCOVER_MORE: 'Discover more',
  },
};

export default en;
